<template>
  <border-box label="Notifications">
    <br />
    <div class="row">
      <div class="col-md-6">
        
        <EmailForm @sent="refresh" />
      </div>
      <div class="col-md-6 mt-3">
        <notification-log :key="renderCount + '_co'"></notification-log>
      </div>
    </div>
  </border-box>
</template>
<script>
import util from "@/util/util";
import borderBox from "../components/Quotation/border-box.vue";
import NotificationLog from "../components/Notification/notification-log.vue";
import EmailForm from "@/components/Contact/PopupForms/EmailForm.vue";

export default {
  components: { borderBox, NotificationLog, EmailForm },
  data() {
    return {
      renderCount: 0,
    };
  },
  methods: {
    headingTransformer: function (val) {
      return util.camelCase(val);
    },
    rowClicked(row) {
      this.$router.push({ name: "single-contact", params: { id: row.id } });
    },
    refresh() {
      this.renderCount++;
    },
  },
};
</script>